console.log("📝 TextResizer Script imported!");
//
//
//
var fontSizeSetting = parseInt(localStorage.getItem("localRFS"));

function setFontSizes(setting) {
  document.documentElement.style.fontSize = setting + "px";
}

if (fontSizeSetting) {
  setFontSizes(fontSizeSetting);
} else {
  fontSizeSetting = 16;
}

function changeFontSizes(direction) {
  if (direction === "down") {
    //downs the value
    if (fontSizeSetting > 6) {
      fontSizeSetting -= 0.5;
    } else {
      console.log("📝🛑 RFS must not be smaller than 6px!");
    }
  } else if (direction === "reset") {
    //resets the value
    fontSizeSetting = 16;
  } else {
    //ups the value
    if (fontSizeSetting < 32) {
      fontSizeSetting += 0.5;
    } else {
      console.log("📝🛑 RFS must not be larger than 32px!");
    }
  }
  localStorage.setItem("localRFS", fontSizeSetting);
  setFontSizes(fontSizeSetting);
}

const textResizerButton = document.querySelector("#textResizerButton");
const textResizerButtonMinus = document.querySelector("#textResizerButtonMinus");
const textResizerButtonReset = document.querySelector("#textResizerButtonReset");
const textResizerButtonPlus = document.querySelector("#textResizerButtonPlus");

textResizerButton.addEventListener("click", function () {
  var element = document.querySelector("#textResizerControl"),
    firstIcon = document.querySelector("#textResizerButton > svg:first-of-type"),
    lastIcon = document.querySelector("#textResizerButton > svg:last-of-type");
  element.classList.toggle("expanded");
  firstIcon.classList.toggle("hidden");
  lastIcon.classList.toggle("hidden");
});

textResizerButtonMinus.addEventListener("click", function () {
  changeFontSizes("down");
});

textResizerButtonReset.addEventListener("click", function () {
  changeFontSizes("reset");
});

textResizerButtonPlus.addEventListener("click", function () {
  changeFontSizes("up");
});
