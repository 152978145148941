import dictionary from "../dictionary.json";
import { textContent } from "./builder.js";
//
console.log("📖 TermHighlighter Script imported!");
//
//
//
//get number of ITEMS from dictionary
var indexOfItem = Object.keys(dictionary);
console.log("📖 " + indexOfItem.length + " entries found in dictionary");

//get data for every single ITEM from dictionary
export function highlightTerms() {
  for (let i = 0; i < indexOfItem.length; i++) {
    let dictItemTerm = dictionary[i].term,
      dictItemHyphenation = dictionary[i].hyphenation,
      dictItemDescription = dictionary[i].description,
      dictItemExample = dictionary[i].example,
      dictItemImage = dictionary[i].image;
    //check if dictionary is not empty
    if (dictItemTerm !== "") {
      // get TEXT of page
      let text = textContent.innerHTML;
      // check for all instances of any given TERM in TEXT
      let re = new RegExp(dictItemTerm, "g");
      // highlight TERM in TEXT and add data-attributes
      let newText = text.replace(
        re,
        `<mark data-term="${dictItemTerm}" class="bg-tangerine-200 dark:bg-tangerine-300">${dictItemTerm}<div
        class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full justify-center p-4 text-center items-center">
                <div
                    class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div class="bg-white px-4 pt-8 pb-6 sm:p-8 sm:pb-6">
                        <div class="flex flex-col items-start text-left space-y-3">
                                <div class="flex items-baseline mb-3">
                                    <h3 class="text-2xl font-bold leading-6 text-gray-900 mr-4" id="modal-title">
                                        ${dictItemTerm}</h3><span
                                        class="text-sm text-gray-600 inline">${dictItemHyphenation}</span>
                                </div>
                                <div>
                                    <p class="text-sm font-bold text-tangerine-400">Erklärung:</p>
                                    <p class="text-lg italic text-gray-800">${dictItemDescription}</p>
                                </div>
                                <div>
                                    <p class="text-sm font-bold text-tangerine-400">Beispiele:</p>
                                    <p class="text-lg italic text-gray-800">${dictItemExample}</p>
                                </div>` +
          `<div>
                                    <p class="text-sm font-bold text-tangerine-400">Bild:</p>
                                    <img src="./assets/images/dictionary/nordamerika.svg" alt=""></div>` +
          `</div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button"
                            class="closeButton inline-flex w-full justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">Schließen</button>
                    </div>
                </div>
            </div>
        </div>
    </div></mark>`
      );
      // set new TEXT
      textContent.innerHTML = newText;
    }
  }
}
