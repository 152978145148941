console.log("💡 Themer Script imported!");
//
//
//
// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage.localCT === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
  document.documentElement.classList.add("dark");
} else {
  document.documentElement.classList.remove("dark");
}
const themeModeButton = document.querySelector("#themeModeButton");
themeModeButton.addEventListener("click", function () {
  // if set via local storage previously
  if (localStorage.getItem("localCT")) {
    if (localStorage.getItem("localCT") === "light") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("localCT", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("localCT", "light");
    }
    // if NOT set via local storage previously
  } else {
    if (document.documentElement.classList.contains("dark")) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("localCT", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("localCT", "dark");
    }
  }
});
