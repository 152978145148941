[
  {
    "term": "Schädling",
    "hyphenation": "Schäd·ling",
    "description": "Schädlinge sind Tiere oder Pflanzen, die dem Menschen schaden.",
    "example": "Schimmelpilz, Kopflaus, Motte",
    "image": false
  },
  {
    "term": "Nordamerika",
    "hyphenation": "Nord·ame·ri·ka",
    "description": "Nordamerika ist der nördliche Teil von Amerika.",
    "example": "Amerika, Kanada und Mexiko liegen in Nordamerika",
    "image": true
  },
  {
    "term": "Gewässern",
    "hyphenation": "Ge·wäs·sern",
    "description": "Gewässer sind größere Mengen Wasser in der Natur.",
    "example": "See, Meer, Fluss",
    "image": false
  }
]
