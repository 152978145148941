// themer
import * as themer from "./themer.js";
// font resizer
import * as resizer from "./resizer.js";
// builder
import * as builder from "./builder.js";
// highlighter
import * as highlighter from "./highlighter.js";
// DOM will be available here
(function () {
  // call highliter only on pages containing factuals
  if (document.querySelector("body").dataset.factual === "true") {
    builder.buildArticle();
    highlighter.highlightTerms();
  }
  // listener for highlited terms
  //click listener for every TERM in TEXT
  var termsInText = document.querySelectorAll("mark");
  for (let i = 0; i < termsInText.length; i++) {
    termsInText[i].addEventListener("click", function handleClick(event) {
      event.target.classList.add("clicked");
    });
  }
  //click listener for every close button
  var closeButtoons = document.querySelectorAll(".closeButton");
  for (let i = 0; i < closeButtoons.length; i++) {
    closeButtoons[i].addEventListener("click", closeModal);
  }
  //
  //
  //
  function closeModal() {
    document.querySelectorAll("mark").forEach((item) => item.classList.remove("clicked"));
  }
})();
