console.log("📄 PageBuilder Script imported!");
//
//
//
// import bisamratte from "../bisamratte.json";
//
var textTitle = document.querySelector("#text-title"),
  textContent = document.querySelector("#text-paragraph");

export function buildArticle() {
  textTitle.textContent = "Die Bisamratte";
  // textContent.innerHTML = `
  // <h3 class="text-2xl mt-6 text-black resizable">Hier lebt die Bisamratte:</h3>
  // Die Bisamratte kommt aus Nordamerika. <br/>Heute findet man sie auf der ganzen Welt.<br/>Die Bisamratte hält sich an Flüssen und anderen Gewässern auf.
  // <h3 class="text-2xl mt-6 text-black resizable">Aussehen der Bisamratte:</h3>
  // <p class="resizable">Die Bisamratte ist ungefähr so groß, wie XXX.<br/>Sie hat einen langen nackten Schwanz.<br/>Die Bisamratte hat braunes Fell, spitze Vorderzähne und kleine Augen.</p>
  // <h3 class="text-2xl mt-6 text-black resizable">Ist die Bisamratte ein Schädling?</h3>
  // <p>Die Bisamratte gräbt Löcher und Gänge dadurch entstehen Schäden.<br/>Sie frisst viele Muscheln und seltene Pflanzen.<br/>Trotzdem hat die Bisamratte sich gut in die Lebensräume der Menschen eingefügt</p>
  // `;
}

export var textTitle, textContent;
